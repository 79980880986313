import { EVENTS } from 'www/library.blocks/common/b-choose-town-btn/constants'

require('libs/magnific-popup/magnific-popup.css')
require('libs/magnific-popup/jquery.magnific-popup.min')

const $bChooseTownPopup = $('.b-choose-town-popup') // элемент попапа с выбором города
// eslint-disable-next-line camelcase
const $__towns = $bChooseTownPopup.find('.b-choose-town-popup__towns') // контейнер внутри попапа со списком городов

const $bTownSwitcher = $('.b-choose-town-btn-v2')

const mfp = $.magnificPopup.instance

const popup = $bTownSwitcher.magnificPopup({
  type: 'inline',
  removalDelay: 200,
  closeOnBgClick: true,
  callbacks: {
    beforeOpen() {
      this.st.mainClass = 'mag-popup'
      this.st.mainClass += ` ${this.st.el.attr('data-effect')}`
    },
  },
})

const switchTown = () => {
  // eslint-disable-next-line camelcase
  if ($__towns.text() === '') {
    $.ajax({
      type: 'GET',
      url: $bTownSwitcher.data('url'),
      success(data) {
        // eslint-disable-next-line camelcase
        $__towns.html(data)

        const t = setTimeout(() => {
          try {
            if (mfp) {
              mfp.updateItemHTML() // в методе библиотеки возникает ошибка, которая летит в 'Sentry'
            }
                    } catch {} // eslint-disable-line

          clearTimeout(t)
        }, 200)
      },
    })
  }
}

$bTownSwitcher.on('click', e => {
  e.preventDefault()
  switchTown()
})

window.addEventListener(EVENTS.switchTown, () => {
  switchTown()
  popup.magnificPopup('open')
})
