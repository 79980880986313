/* eslint-disable */
;(function () {
    require('imports-loader?define=>false!libs/typeahead/corejavascript/typeahead.jquery.min.js');
    const Bloodhound = require('imports-loader?define=>false!libs/typeahead/corejavascript/bloodhound.min.js');

    const bemBlockClass = 'b-town-search';
    const typeaheadBemClassNames = { // новые имена классов для typeahead по БЭМ
        input: `${bemBlockClass}__input`,
        selectable: `${bemBlockClass}__selectable`,
        suggestion: `${bemBlockClass}__suggestion`,
        highlight: `${bemBlockClass}__highlight`,
        menu: `${bemBlockClass}__menu`,
        empty: `${bemBlockClass}__empty`,
        label: `${bemBlockClass}__label`
    };

    const $bTownSearch = $(`.${bemBlockClass}`);
    const $bTownSearchInput = $bTownSearch.find(`.${bemBlockClass}__input`); // input элемент на котором активируется typeahead

    const csrfToken = $bTownSearch.data('csrf');
    const acUrl = $bTownSearch.data('url');

    const WILDCARD = '%QUERY';

    const searchResults = new Bloodhound({ // результаты поиска по городам с ajax запросом
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: '',
        remote: {
            cache: false,
            url: `${acUrl}?q=${WILDCARD}`,
            prepare: function (query, settings) {
                settings.url = settings.url.replace(WILDCARD, encodeURIComponent(query));
                return settings;
            },
            wildcard: WILDCARD
        }
    });

    /**
     * Рисует отдельный элемент в списке предложений поиска
     * @param suggestion - объект элемента
     * @returns {string}
     */
    function renderSuggestion (suggestion) {
        if (!suggestion) {
            return '<div></div>';
        }

        return `<div class="${typeaheadBemClassNames.suggestion}">
                    <span data-translit="${suggestion.translit}" data-url="${suggestion.url}" class="${bemBlockClass}__suggestion-link">${suggestion.name}</span>
               </div>`
    }

    $bTownSearchInput.typeahead({
        minLength: 3,
        highlight: true,
        hint: false,
        classNames: typeaheadBemClassNames
    },
    {
        limit: 5,
        name: 'search-results',
        display: 'value',
        source: searchResults,
        templates: {
            suggestion: renderSuggestion,
            empty: `<div class="${typeaheadBemClassNames.empty}">Совпадений не найдено</div>`
        }
    });

    function ajaxTownPostQuery (url) {
        $.ajax({
            type: "POST",
            data: {
                csrfmiddlewaretoken: csrfToken
            },
            url,
            success: function (data) {
                window.location = data.url;
            }
        });
    }

    if (($bTownSearchInput.val() || {}).length > 0) {
        $(`.${typeaheadBemClassNames.label}`).addClass(`${typeaheadBemClassNames.label}_input_active`);
    }

    $bTownSearchInput.on('typeahead:active', function () {
        $(`.${typeaheadBemClassNames.label}`).addClass(`${typeaheadBemClassNames.label}_input_active`);
    });

    $bTownSearchInput.on('typeahead:idle', function () {
        if ($(this).val().length > 0) {
            return;
        }

        $(`.${typeaheadBemClassNames.label}`).removeClass(`${typeaheadBemClassNames.label}_input_active`);
    });

    /**
     * При выборе элемента в предложениях, сразу переходить на страницу нужного города
     */
    $bTownSearchInput.on('typeahead:selected', function (e, townObj) {
        ajaxTownPostQuery(townObj.url);
    });

    $('body').on('click', `.${bemBlockClass}__suggestion-link`, function (e) {
        e.preventDefault();

        ajaxTownPostQuery($(this).data('url'));
    });
}());
