const csrf = $('.b-choose-town-popup').data('csrf')

$('body').on('click', '.b-choose-town-popup__towns-item-link', function handler(e) {
  e.preventDefault()

  $.ajax({
    type: 'POST',
    data: {
      csrfmiddlewaretoken: csrf,
    },
    url: $(this).data('url'),
    success(data) {
      window.location = data.url
    },
  })
})
