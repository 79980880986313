import { LOGIN_LINKS } from 'constants/links'
import Hint from 'modules/Hint'

const blockName = 'b-choose-login-type'
const triggerBtnClass = `${blockName}__trigger-btn`
const triggerBtnNode = document.querySelector(`.${triggerBtnClass}`)

// не на всех страницах есть header
if (triggerBtnNode) {
  const chooseAuthTypeHintContent = `
    <div class="${blockName}">
      <div class="pb-4 mb-4 ${blockName}__login-type">
        <div class="ui-text ui-text_subtitle-1 ui-kit-color-text mb-2">
          Пациент
        </div>
        <div class="ui-text ui-text_caption ui-kit-color-text-secondary mb-2">
          Делитесь отзывами о врачах, записывайтесь на приём онлайн, ведите электронную медкарту
        </div>
        <a href="${LOGIN_LINKS.medtochka}"
           class="b-button b-button_blue b-button_small"
        >
          <span class="ui-text ui-text_button_small">Войти</span>
        </a>
      </div>
      <div class="${blockName}__login-type">
        <div class="ui-text ui-text_subtitle-1 ui-kit-color-text mb-2">
          Врач или клиника
        </div>
        <div class="ui-text ui-text_caption ui-kit-color-text-secondary mb-2">
          Отвечайте на отзывы пациентов, включайте запись на приём, рассказывайте о ваших услугах
        </div>
        <a href="${LOGIN_LINKS.cabinet}"
           class="b-button b-button_blue b-button_small"
        >
          <span class="ui-text ui-text_button_small">Войти</span>
        </a>
      </div>
    </div>`

  new Hint({
    text: chooseAuthTypeHintContent,
    target: `.${triggerBtnClass}`,
    isMobile: false,
    modifier: blockName,
    tippyOptions: {
      interactive: true,
      placement: 'bottom-end',
    },
  })
}
